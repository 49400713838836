import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
    },
    {
      path: "/",
      component: () => import("../components/Home.vue"),
      meta: { title: "home" },
      children: [
        {
          path: "dashboard",
          component: () => import("../pages/dashboard"),
          meta: { title: "工作台", keepAlive: false },
        },
        {
          path: "managementAccounts",
          component: () => import("../pages/dashboard/managementAccounts"),
          meta: { title: "账户管理", keepAlive: false },
        },
        /* {
					path: 'customer',
					component: () => import('../pages/customer'),
					meta: { title: '客户管理' }
				}, */
        {
          path: "customerInput",
          component: () => import("../pages/customer/customerInput.vue"),
          meta: { title: "客户录入", keepAlive: false },
        },
        {
          path: "customerManagement",
          component: () => import("../pages/customer/customerManagement.vue"),
          meta: { title: "管理客户", keepAlive: false },
        },
        {
          path: "customerDetall",
          component: () => import("../pages/customer/customerDetall.vue"),
          meta: { title: "客户详情", keepAlive: false },
        },
        {
          path: "afterSalesDetall",
          component: () => import("../pages/customer/afterSalesDetall.vue"),
          meta: { title: "售后详情", keepAlive: false },
        },
        {
          path: "totalCustomer",
          component: () => import("../pages/customer/totalCustomer.vue"),
          meta: { title: "总客户池", keepAlive: false },
        },
        {
          path: "returnVisit",
          component: () => import("../pages/customer/returnVisit.vue"),
          meta: { title: "待回访区", keepAlive: false },
        },
        {
          path: "returnVisit",
          component: () => import("../pages/customer/returnVisit.vue"),
          meta: { title: "客户跟进分析", keepAlive: false },
        },
        {
          path: "returnVisit",
          component: () => import("../pages/customer/returnVisit.vue"),
          meta: { title: "客户跟进分析", keepAlive: false },
        },
        {
          path: "memberReturnVisit",
          component: () => import("../pages/customer/memberReturnVisit.vue"),
          meta: { title: "成员客户跟进分析", keepAlive: false },
        },
        {
          path: "followUpAnalyse",
          component: () => import("../pages/customer/followUpAnalyse.vue"),
          meta: { title: "客户跟进分析", keepAlive: false },
        },
        /* 	{
					path: 'form',
					component: () => import('../pages/form'),
					meta: { title: '企业管理' }
				}, */
        {
          path: "corporateInformation",
          component: () =>
            import("../pages/businessManagement/corporateInformation"),
          meta: { title: "企业信息", keepAlive: false },
        },
        {
          path: "enterpriseMember",
          component: () =>
            import("../pages/businessManagement/enterpriseMember"),
          meta: { title: "企业成员", keepAlive: false },
        },
        {
          path: "memberDetall",
          component: () => import("../pages/businessManagement/memberDetall"),
          meta: { title: "成员详情", keepAlive: false },
        },
        {
          path: "sectorSetup",
          component: () =>
            import("../pages/businessManagement/sectorSetup/sectorSetup"),
          meta: { title: "部门设置", keepAlive: false },
        },
        {
          path: "AdministratorList",
          component: () =>
            import(
              "../pages/businessManagement/AdministratorList/AdministratorList"
            ),
          meta: { title: "管理员列表", keepAlive: false },
        },
        {
          path: "AdministratorDetail",
          component: () =>
            import(
              "../pages/businessManagement/AdministratorList/AdministratorDetail"
            ),
          meta: { title: "管理员设置", keepAlive: false },
        },
        {
          path: "WeChatClient",
          component: () => import("../pages/businessManagement/WeChatClient"),
          meta: { title: "客户企微数据", keepAlive: false },
        },
        {
          path: "WeChatClient",
          component: () => import("../pages/businessManagement/WeChatClient"),
          meta: { title: "客户企微数据", keepAlive: false },
        },
        {
          path: "launchProducts",
          component: () =>
            import("../pages/merchandiseManagement/appointment/management"),
          meta: { title: "发布产品", keepAlive: false },
        },
        {
          path: "advancedForm",
          component: () => import("../pages/businessManagement/advancedForm"),
          meta: { title: "高级表单", keepAlive: false },
        },
        {
          path: "performanceAssessment",
          component: () =>
            import("../pages/businessManagement/performanceAssessment"),
          meta: { title: "企业成员审核", keepAlive: false },
        },
        {
          path: "theInspectionRules",
          component: () =>
            import("../pages/businessManagement/theInspectionRules"),
          meta: { title: "考核规则", keepAlive: false },
        },
        {
          path: "theAssessmentDetails",
          component: () =>
            import("../pages/businessManagement/theAssessmentDetails"),
          meta: { title: "考核详情", keepAlive: false },
        },
        {
          path: "publishAdvancedForm",
          name: "publishAdvancedForm",
          component: () =>
            import("../pages/businessManagement/publishAdvancedForm"),
          meta: { title: "新增表单", keepAlive: false },
        },
        {
          path: "formDetall",
          name: "formDetall",
          component: () => import("../pages/businessManagement/formDetall"),
          meta: { title: "表单详情", keepAlive: false },
        },
        // {
        // 	path: 'websiteSettings',
        // 	component: () => import('../pages/businessManagement/websiteSettings'),
        // 	meta: { title: '官网设置' }
        // },
        {
          path: "advertisingManagement",
          component: () =>
            import("../pages/businessManagement/advertisingManagement"),
          meta: { title: "广告图管理", keepAlive: false },
        },
        {
          path: "setBanner",
          component: () => import("../pages/businessManagement/setBanner"),
          meta: { title: "广告图设置", keepAlive: false },
        },
        {
          path: "orderManagement",
          component: () => import("../pages/orderManagement"),
          meta: { title: "订单管理", keepAlive: false },
        },
        {
          path: "financialStatistics",
          component: () =>
            import("../pages/financialManagement/financialStatistics"),
          meta: { title: "财务统计", keepAlive: false },
        },
        {
          path: "moneyRecord",
          component: () => import("../pages/financialManagement/moneyRecord"),
          meta: { title: "资金记录", keepAlive: false },
        },
        {
          path: "WithdrawalRecord",
          component: () =>
            import("../pages/financialManagement/WithdrawalRecord"),
          meta: { title: "提现记录", keepAlive: false },
        },
        {
          path: "commissionManagement",
          component: () =>
            import("../pages/financialManagement/commissionManagement"),
          meta: { title: "佣金管理", keepAlive: false },
        },
        {
          path: "accountManagement",
          component: () =>
            import("../pages/financialManagement/accountManagement"),
          meta: { title: "账户管理", keepAlive: false },
        },
        {
          path: "moneyForDetails",
          component: () =>
            import("../pages/financialManagement/moneyForDetails"),
          meta: { title: "公司佣金详情", keepAlive: false },
        },
        {
          path: "partnerList",
          component: () => import("../pages/TurnToIntroduce/partnerList"),
          meta: { title: "合作伙伴列表", keepAlive: false },
        },
        {
          path: "partnerListDetailList",
          component: () =>
            import("../pages/TurnToIntroduce/partnerListDetailList"),
          meta: { title: "列表详情", keepAlive: false },
        },
        {
          path: "partnerListDetail",
          component: () => import("../pages/TurnToIntroduce/partnerListDetail"),
          meta: { title: "合作伙伴详情", keepAlive: false },
        },
        {
          path: "rulesSetting",
          component: () => import("../pages/TurnToIntroduce/rulesSetting"),
          meta: { title: "规则设置", keepAlive: false },
        },
        {
          path: "withdrawalList",
          component: () => import("../pages/TurnToIntroduce/withdrawalList"),
          meta: { title: "赏金提现列表", keepAlive: false },
        },
        {
          path: "rulesSetting",
          component: () => import("../pages/TurnToIntroduce/rulesSetting"),
          meta: { title: "规则设置", keepAlive: false },
        },
        {
          path: "agentManage",
          component: () => import("../pages/agentManage/agentManage"),
          meta: { title: "代理企业", keepAlive: false },
        },
        {
          path: "companyDetail",
          component: () => import("../pages/agentManage/companyDetail"),
          meta: { title: "代理企业详情", keepAlive: false },
        },
        {
          path: "personalCommissionDetall",
          component: () =>
            import("../pages/financialManagement/personalCommissionDetall"),
          meta: { title: "个人佣金详情", keepAlive: false },
        },
        {
          path: "moneyForDetails",
          component: () =>
            import("../pages/financialManagement/moneyForDetails"),
          meta: { title: "公司佣金详情", keepAlive: false },
        },
        {
          path: "moneyManagement",
          component: () =>
            import("../pages/financialManagement/moneyManagement"),
          meta: { title: "资金管理", keepAlive: false },
        },
        {
          path: "management",
          component: () => import("../pages/merchandiseManagement/management"),
          meta: { title: "商品管理", keepAlive: false },
        },
        {
          path: "appointmentManagement",
          component: () =>
            import("../pages/merchandiseManagement/appointmentManagement"),
          meta: { title: "预约管理", keepAlive: false },
        },
        {
          path: "freightManagement",
          component: () =>
            import("../pages/merchandiseManagement/freightManagement"),
          meta: { title: "运费管理", keepAlive: false },
        },
        {
          path: "distributionStatistics",
          component: () =>
            import(
              "../pages/marketingActivities/distribution/distributionStatistics"
            ),
          meta: { title: "分销统计", keepAlive: false },
        },
        {
          path: "personnelApplication",
          component: () =>
            import(
              "../pages/marketingActivities/distribution/personnelApplication"
            ),
          meta: { title: "人员申请", keepAlive: false },
        },
        {
          path: "distributionLevel",
          component: () =>
            import(
              "../pages/marketingActivities/distribution/distributionLevel"
            ),
          meta: { title: "分销等级", keepAlive: false },
        },
        {
          path: "distributionOrder",
          component: () =>
            import(
              "../pages/marketingActivities/distribution/distributionOrder"
            ),
          meta: { title: "分销订单", keepAlive: false },
        },
        {
          path: "accountTransfer",
          component: () =>
            import("../pages/marketingActivities/distribution/accountTransfer"),
          meta: { title: "到账流水", keepAlive: false },
        },
        {
          path: "productList",
          component: () =>
            import("../pages/marketingActivities/joinGroup/productList"),
          meta: { title: "拼团商品列表", keepAlive: false },
        },
        {
          path: "listParticipants",
          component: () =>
            import("../pages/marketingActivities/joinGroup/listParticipants"),
          meta: { title: "参团列表", keepAlive: false },
        },
        {
          path: "assembleOrdersInfo",
          component: () =>
            import("../pages/marketingActivities/joinGroup/assembleOrdersInfo"),
          meta: { title: "订单详情", keepAlive: false },
        },
        {
          path: "addAssemble",
          component: () =>
            import(
              "../pages/marketingActivities/joinGroup/components/addAssemble"
            ),
          meta: { title: "拼团配置", keepAlive: false },
        },
        {
          path: "spikeList",
          component: () => import("../pages/marketingActivities/spike"),
          meta: { title: "秒杀商品列表", keepAlive: false },
        },
        {
          path: "addSpike",
          component: () =>
            import("../pages/marketingActivities/spike/components/addSpike"),
          meta: { title: "秒杀设置", keepAlive: false },
        },
        {
          path: "couponList",
          component: () =>
            import("../pages/marketingActivities/couponManagement/couponList"),
          meta: { title: "优惠券列表", keepAlive: false },
        },
        {
          path: "detailCoupon",
          component: () =>
            import(
              "../pages/marketingActivities/couponManagement/detailCoupon"
            ),
          meta: { title: "领取详情", keepAlive: false },
        },

        {
          path: "customerArticles",
          component: () =>
            import("../pages/articleManagement/customerArticles"),
          meta: { title: "获客文章", keepAlive: false },
        },
        {
          path: "isDrafts",
          component: () => import("../pages/articleManagement/isDrafts"),
          meta: { title: "获客文章草稿箱", keepAlive: false },
        },
        {
          path: "employeeData",
          component: () => import("../pages/articleManagement/employeeData"),
          meta: { title: "员工数据", keepAlive: false },
        },
        {
          path: "staffDataDetail",
          component: () =>
            import("../pages/articleManagement/components/staffDataDetail"),
          meta: { title: "员工数据", keepAlive: false },
        },

        {
          path: "visitorData",
          component: () => import("../pages/articleManagement/visitorData"),
          meta: { title: "访客数据", keepAlive: false },
        },
        {
          path: "activityRegistration",
          component: () =>
            import("../pages/articleManagement/activityRegistration"),
          meta: { title: "活动报名管理", keepAlive: false },
        },
        {
          path: "addActivity",
          component: () => import("../pages/articleManagement/addActivity"),
          meta: { title: "新增活动", keepAlive: false },
        },
        {
          path: "activityDetails",
          component: () => import("../pages/articleManagement/activityDetails"),
          meta: { title: "活动详情", keepAlive: false },
        },
        {
          path: "dynamicBasicSetting",
          component: () =>
            import("../pages/articleManagement/dynamicBasicSetting"),
          meta: { title: "基础设置", keepAlive: false },
        },
        {
          path: "videoManage",
          component: () => import("../pages/videoManage"),
          meta: { title: "短视频管理", keepAlive: false },
        },
        {
          path: "myNeeds",
          component: () => import("../pages/addedServices/myNeeds"),
          meta: { title: "我的需求", keepAlive: false },
        },
        {
          path: "demandCheck",
          component: () => import("../pages/addedServices/demandCheck"),
          meta: { title: "需求审核", keepAlive: false },
        },
        {
          path: "demandContactsCheck",
          component: () => import("../pages/addedServices/demandContactsCheck"),
          meta: { title: "人脉集市审核", keepAlive: false },
        },
        {
          path: "demandProtuctsCheck",
          component: () => import("../pages/addedServices/demandProtuctsCheck"),
          meta: { title: "产品中心审核", keepAlive: false },
        },
        {
          path: "demandPublishSetting",
          component: () =>
            import("../pages/addedServices/demandPublishSetting"),
          meta: { title: "发布设置", keepAlive: false },
        },
        {
          path: "demandReport",
          component: () => import("../pages/addedServices/demandReport"),
          meta: { title: "举报处理", keepAlive: false },
        },
        {
          path: "demandCustomize",
          component: () => import("../pages/addedServices/demandCustomize"),
          meta: { title: "自定义设置", keepAlive: false },
        },
        {
          path: "demandPublishForm",
          component: () =>
            import("../pages/addedServices/components/demandPublishForm"),
          meta: { title: "发布需求", keepAlive: false },
        },
        {
          path: "posterAtlas",
          component: () =>
            import("../pages/addedServices/sharingPromotion/posterAtlas"),
          meta: { title: "海报图集", keepAlive: false },
        },
        {
          path: "posterAtlasDetall",
          component: () =>
            import("../pages/addedServices/sharingPromotion/posterAtlasDetall"),
          meta: { title: "海报图集详情", keepAlive: false },
        },
        {
          path: "clockData",
          component: () => import("../pages/addedServices/clockData"),
          meta: { title: "打卡数据", keepAlive: false },
        },
        {
          path: "clockDataDetall",
          component: () =>
            import("../pages/addedServices/clockData/clockDataDetall"),
          meta: { title: "打卡详情", keepAlive: false },
        },
        {
          path: "appletConfig",
          component: () => import("../pages/miniProgram/appletConfig"),
          meta: { title: "小程序配置", keepAlive: false },
        },
        {
          path: "basicSetting",
          component: () => import("../pages/miniProgram/basicSetting"),
          meta: {
            title: "基础设置",
            keepAlive: false,
          },
        },
        // {
        // 	path:'miniProgram',
        // 	component: () => import('../pages/miniProgram'),
        // 	meta: { title: '小程序配置' }
        // },
        {
          path: "mallSetting",
          component: () => import("../pages/miniProgram/mallSetting"),
          meta: { title: "商城设置", keepAlive: true },
        },
        // {
        // 	path:'mallTemplate',
        // 	component: () => import('../pages/miniProgram/mallTemplate'),
        // 	meta: { title: '商城模板设置',keepAlive: false }
        // },
        {
          path: "templateA",
          component: () => import("../pages/miniProgram/templateA"),
          meta: { title: "综合电商", keepAlive: true },
        },
        {
          path: "templateB",
          component: () => import("../pages/miniProgram/templateB"),
          meta: { title: "节日优惠", keepAlive: true },
        },
        {
          path: "templateC",
          component: () => import("../pages/miniProgram/templateC"),
          meta: { title: "日用百货", keepAlive: true },
        },
        {
          path: "templateD",
          component: () => import("../pages/miniProgram/templateD"),
          meta: { title: "数码家电", keepAlive: true },
        },

        {
          path: "goodsDetails",
          component: () =>
            import("../pages/merchandiseManagement/goodsDetails"),
          meta: { title: "商品详情", keepAlive: false },
        },
        {
          path: "redactOrAdd",
          component: () => import("../pages/merchandiseManagement/redactOrAdd"),
          meta: { title: "商品发布", keepAlive: false },
        },
        {
          path: "dataStatistics",
          component: () => import("../pages/aIMarketing/dataStatistics.vue"),
          meta: { title: "数据线索", keepAlive: false },
        },
        {
          path: "telephonerecord",
          component: () => import("../pages/aIMarketing/telephonerecord.vue"),
          meta: { title: "话单记录", keepAlive: false },
        },
        {
          path: "tasklist",
          component: () => import("../pages/aIMarketing/tasklist.vue"),
          meta: { title: "任务列表", keepAlive: false },
        },
        {
          path: "aiVerbalTrick",
          component: () => import("../pages/aIMarketing/aiVerbalTrick.vue"),
          meta: { title: "AI话术", keepAlive: false },
        },
        {
          path: "embeddedURL",
          component: () => import("../pages/aIMarketing/embeddedURL.vue"),
          meta: { title: "探客宝", keepAlive: false },
        },
      ],
    },
    {
      path: "/login",
      component: () => import("../pages/login"),
      meta: { title: "登录" },
    },
    {
      path: "*",
      redirect: "/dashboard",
    },
  ],
});
