export default {
  state: {
    userInfo: JSON.parse(localStorage.getItem("info")),
    loginDate: localStorage.getItem("loginDate"),
  },
  // namespaced: true,
  mutations: {
    setUserInfo(state, data) {
      state.userInfo = data;
    },
  },
  actions: {},
};
