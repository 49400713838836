import Vue from "vue";
import Vuex from "vuex";
import loginRoot from "./loginRoot";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    isShowLoading: false, // 全局 loading
    subjectColor: "#FFFFFF", //主题颜色
    tagColor: "#0D6DFF",
    goodsInfo: {},
    uploadingUrl: "https://one-stand-api.moverse.com/file/uploadObjectOSS", //上传域名
    ossUrl: "https://moverse-stand.oss-cn-beijing.aliyuncs.com/", //静态资源域名
    routerArr: [],
    empId: "", //部门id
  },
  mutations: {
    SET_GOODSINFO(state, data) {
      state.goodsInfo = data;
    },
    setRouterArr(state, data) {
      state.routerArr = data;
    },
    setEmpId(state, data) {
      state.empId = data;
    },
  },
  modules: {
    loginRoot,
  },
});

export default store;
